@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  html {
    font-family: "inter";
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
  }
}

@layer components {
  .mesh-gradient {
    background: linear-gradient(258.38deg, #ff6a25 -17.25%, #cd42ff 48.57%, #8fb9fc 109.33%);
  }

  .mesh-text-gradient {
    background: linear-gradient(to left, #ff6a25 -46%, #cd42ff 78%, #8fb9fc 107%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

:root {
  --mesh-text-gradient: linear-gradient(
    258.38deg,
    #ff6a25 -17.25%,
    #cd42ff 48.57%,
    #8fb9fc 109.33%
  );
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b0d0f;
  /* background-image: url("Assets/Svgs/background.svg");
  background-size: cover;
  background-position: bottom; */
}
#root {
  position: relative;
  /* height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.background {
  background-color: #0b0d0f;
  background-image: url("Assets/Svgs/background.svg");
  background-color: #0b0d0f;
  height: 100%;
  background-size: cover;
  position: fixed;
  z-index: -1;
  bottom: 0px;
  width: 100%;
}

.wallet-adapter-button-trigger {
  background: linear-gradient(258.38deg, #ff6a25 -17.25%, #cd42ff 48.57%, #8fb9fc 109.33%);
  color: #fff;
}

.wallet-adapter-button {
  padding: 8px 16px;
  height: 40px !important;
  border-radius: 2px;
}

.tab-active {
  background: linear-gradient(#ff6a25 -17.25%, #cd42ff 48.57%, #8fb9fc 109.33%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-toast-body {
  align-items: flex-start !important;
  font-family: "inter" !important;
}

.Toastify__toast-icon {
  width: 25px !important;
}

.Toastify__toast-container {
  width: 360px !important;
}

.mesh-border {
  background: linear-gradient(45deg, #8fb9fc, #cd42ff, #ff6a25);
}

.gradient-border {
  position: relative;
  padding: 2px;
  background: linear-gradient(258.38deg, #ff6a25 -17.25%, #cd42ff 48.57%, #8fb9fc 109.33%);
  margin: 1px;
  border-radius: 9px;
}

.gradient-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  background-image: linear-gradient(258.38deg, #ff6a25 -17.25%, #cd42ff 48.57%, #8fb9fc 109.33%);
}

::-webkit-scrollbar {
  width: 11px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #12151a; /* color of the track */
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    258.38deg,
    rgba(255, 106, 37, 0.15) -17.25%,
    rgba(205, 66, 255, 0.15) 48.57%,
    rgba(143, 185, 252, 0.15) 109.33%
  ); /* #0b0d0f; /* color of the thumb */
  border-radius: 16px; /* roundness of the thumb */
}

.light-gradient-bg {
  background: linear-gradient(
    258.38deg,
    rgba(255, 106, 37, 0.15) -17.25%,
    rgba(205, 66, 255, 0.15) 48.57%,
    rgba(143, 185, 252, 0.15) 109.33%
  );
}

.accent-on-hover:hover {
  filter: brightness(0) saturate(100%) invert(38%) sepia(100%) saturate(3386%) hue-rotate(263deg)
    brightness(102%) contrast(102%);
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.icon:hover {
  transform: rotate(30deg); /* Rotate on hover */
}
.input-container {
  position: relative;
}

.input-with-gradient-border {
  width: 100%;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 8px;
  background-color: #0b0d0f;
  color: #fff;
  transition: border-color 0.3s ease;
}

.input-with-gradient-border:focus {
  outline: none;
}

.sol-text {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-left: 6px;
  transform: translateY(-50%);
  background-color: #0b0d0f;
  color: #fff;
  font-size: 10px;
  z-index: 10;
}
.sol-text:hover {
  outline: none;
}

.gradient-border-transparent {
  background: transparent;
}

.banner {
  position: absolute;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(258.38deg, #ff6a25 -17.25%, #cd42ff 48.57%, #8fb9fc 109.33%);
  opacity: 0.15;
  filter: blur(8px);
  z-index: 0;
  height: 100%;
}
.banner-text {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 2;
  width: 100%;
  text-align: center;
}
.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-border-secondary {
  background: #a5b6c8;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
